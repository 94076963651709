import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';


import { useStore } from '../../../../hook-store/store';
import { createTokenForTools } from '../../../../utils/bucket/object-for-tools';
import { 
  getMetadataFromMetaList,
  getParsedMetadataFromObjectMeta,
 } from '../../../../utils/bucket/object-metadata-util';
import { 
  isAudioMimeType, 
  isVideoMimeType,
} from '../../../../utils/bucket/bucket-object-util';
import { mimeTypes } from '../../../../utils/file/mime-type';

import { BACKEND_URL, textAudioToolsLink } from '../../../../App';

import { marks } from '../../../../utils/marks';

import classes from './FileActions.module.css'
// import * as bucketObjectUtils from '../utils/bucket-object-util';


function TextAudioToolsLink(props) {

  const {
    setClickedActionInfoHandler,
    setClickedObjectKeyHandler,
    object,
    // tokenForToolsHandler,
    // isLoading,
  } = props;

  // console.log('ToolsLink.js-props', props, object.Key);
    const [t] = useTranslation('translation');


  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const { bucketName } = store.bucketObjectStore;
  const { 
    allObjectsMetaList,
  } = store.objectMetadataStore;
  const { gLoading } = store.uiStore;

  const [showForImageModal, setShowForImageModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // console.log('object', object);

  let isPresentationFile = false;

  //// user's file case
  const objectMetadata = getMetadataFromMetaList(object.Key, allObjectsMetaList);

  if (objectMetadata && objectMetadata.parsedMetadata.isPresentationFile) {
    isPresentationFile = true;
  }

  //// share file case
  if (object.objectMeta) {
    const parsedMeta = getParsedMetadataFromObjectMeta(object.objectMeta);

    if (parsedMeta && parsedMeta.isPresentationFile && 
        parsedMeta.shareInfo && parsedMeta.shareInfo.ownerId !== localStorage.getItem('userId')
    ) {
      isPresentationFile = true;
    }
    // console.log('object parsedMeta', parsedMeta);
  }

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  const openEditLinkModalHandler = (openApp) => {
    setClickedObjectKeyHandler('');
    dispatch('SET_SHOWEDITLINKMODAL', true);
    dispatch('SET_EDITLINKMODALDATA', {
      key: object.Key,
      openApp: openApp,
      mimeType: object.mimeType ? object.mimeType : '',
    });
  };


  let textAudioToolsLinkBody
  
  
  if (isAudioMimeType(object.mimeType)) {
    textAudioToolsLinkBody = (
      <>
        <button className={classes.objectActionListItem}
          style={{ paddingLeft: "1rem" }}
          disabled={isLoading}
          name="open-in-audio-transcriber"
          onClick={(event) => {
            if (!isLoading) {
              setClickedActionInfoHandler(event, object.Key);
              setClickedObjectKeyHandler('');
            }
          }}
        >
          <a 
            // href={`${dreamInpaniterLink}/?key=${object.Key}&mimeType=${object.mimeType}&tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
            href={`${textAudioToolsLink}/audio-transcriber/?key=${object.Key}&mimeType=${object.mimeType}&kuraStorageUserId=${localStorage.getItem('userId')}&openApp=audioTranscriber`}
            target="_blank" rel="noreferrer noopener"
            style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
          >
            {marks.editMark}{' '}
            {t('fileAction.26', 'open in')}
            {' '}Audio Transcriber {marks.externalLinkMark}
          </a>
        </button>
        <button className={classes.objectActionListItem}
          style={{ paddingLeft: "1rem" }}
          disabled={isLoading}
          name="open-in-audio-slicer"
          onClick={(event) => {
            if (!isLoading) {
              setClickedActionInfoHandler(event, object.Key);
              setClickedObjectKeyHandler('');
            }
          }}
        >
          <a 
            // href={`${dreamInpaniterLink}/?key=${object.Key}&mimeType=${object.mimeType}&tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
            href={`${textAudioToolsLink}/audio-editor/?keyData=${JSON.stringify(
              {
                files: [{ key: object.Key, mimeType: object.mimeType }],
              }
            )}&kuraStorageUserId=${localStorage.getItem('userId')}&openApp=audioSlicer`}
            target="_blank" rel="noreferrer noopener"
            style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
          >
            {marks.editMark}{' '}
            {t('fileAction.26', 'open in')}
            {' '}Audio Slicer {marks.externalLinkMark}
          </a>
        </button>
        <button className={classes.objectActionListItem}
          style={{ paddingLeft: "1rem" }}
          disabled={isLoading}
          name="open-in-audio-combiner"
          onClick={(event) => {
            if (!isLoading) {
              setClickedActionInfoHandler(event, object.Key);
              setClickedObjectKeyHandler('');
              openEditLinkModalHandler('audioCombiner');
            }
          }}
        >
          {marks.editMark}{' '}
          {t('fileAction.26', 'open in')}
          {' '}Audio Combiner
        </button>
        <button className={classes.objectActionListItem}
          style={{ paddingLeft: "1rem" }}
          disabled={isLoading}
          name="open-in-audio-amplitude-changer"
          onClick={(event) => {
            if (!isLoading) {
              setClickedActionInfoHandler(event, object.Key);
              setClickedObjectKeyHandler('');
            }
          }}
        >
          <a 
            // href={`${dreamInpaniterLink}/?key=${object.Key}&mimeType=${object.mimeType}&tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
            href={`${textAudioToolsLink}/audio-editor/?keyData=${JSON.stringify(
              {
                files: [{ key: object.Key, mimeType: object.mimeType }],
              }
            )}&kuraStorageUserId=${localStorage.getItem('userId')}&openApp=audioAmpChanger`}
            target="_blank" rel="noreferrer noopener"
            style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
          >
            {marks.editMark}{' '}
            {t('fileAction.26', 'open in')}
            {' '}Audio Amplitude Changer {marks.externalLinkMark}
          </a>
        </button>
        {object.mimeType !== 'audio/mp3' && (          
          <button className={classes.objectActionListItem}
            style={{ paddingLeft: "1rem" }}
            disabled={isLoading}
            name="open-in-audio-compress-audio-file"
            onClick={(event) => {
              if (!isLoading) {
                setClickedActionInfoHandler(event, object.Key);
                setClickedObjectKeyHandler('');
              }
            }}
          >
            <a 
              // href={`${dreamInpaniterLink}/?key=${object.Key}&mimeType=${object.mimeType}&tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
              href={`${textAudioToolsLink}/audio-editor/?keyData=${JSON.stringify(
                {
                  files: [{ key: object.Key, mimeType: object.mimeType }],
                }
              )}&kuraStorageUserId=${localStorage.getItem('userId')}&openApp=audioConvertToMP3`}
              target="_blank" rel="noreferrer noopener"
              style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
            >
              {marks.editMark}{' '}
              {t('fileAction.26', 'open in')}
              {' '}Compress Audio File {marks.externalLinkMark}
            </a>
          </button>
        )}
                <button className={classes.objectActionListItem}
          style={{ paddingLeft: "1rem" }}
          disabled={isLoading}
          name="open-in-audio-combiner"
          onClick={(event) => {
            if (!isLoading) {
              setClickedActionInfoHandler(event, object.Key);
              setClickedObjectKeyHandler('');
              openEditLinkModalHandler('audioMixer');
            }
          }}
        >
          {marks.editMark}{' '}
          {t('fileAction.26', 'open in')}
          {' '}Audio Mixer
        </button>
      </>
    );
  }

  if (!isPresentationFile && object.mimeType === mimeTypes.txt) {
    textAudioToolsLinkBody = (
      <>
        <button className={classes.objectActionListItem}
          style={{ paddingLeft: "1rem" }}
          disabled={isLoading}
          name="open-in-voice-generator"
          onClick={(event) => {
            if (!isLoading) {
              setClickedActionInfoHandler(event, object.Key);
              setClickedObjectKeyHandler('');
              // tokenForToolsHandler(
              //   BACKEND_URL,
              //   localStorage.getItem('token'),
              //   fileBucket,
              //   object.Key,
              // );
            }
          }}
        >
          <a 
            // href={`${dreamInpaniterLink}/?key=${object.Key}&mimeType=${object.mimeType}&tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
            href={`${textAudioToolsLink}/?key=${object.Key}&mimeType=${object.mimeType}&kuraStorageUserId=${localStorage.getItem('userId')}&openApp=voiceGenerator`}
            target="_blank" rel="noreferrer noopener"
            style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
          >
            {marks.editMark}{' '}
            {t('fileAction.26', 'open in')}
            {' '}Voice Generator (for English) {marks.externalLinkMark}
          </a>
        </button>
        <button className={classes.objectActionListItem}
          style={{ paddingLeft: "1rem" }}
          disabled={isLoading}
          name="open-in-voice-generator-noen"
          onClick={(event) => {
            if (!isLoading) {
              setClickedActionInfoHandler(event, object.Key);
              setClickedObjectKeyHandler('');
              // tokenForToolsHandler(
              //   BACKEND_URL,
              //   localStorage.getItem('token'),
              //   fileBucket,
              //   object.Key,
              // );
            }
          }}
        >
          <a 
            // href={`${dreamInpaniterLink}/?key=${object.Key}&mimeType=${object.mimeType}&tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
            href={`${textAudioToolsLink}/voice-generator-noen?key=${object.Key}&mimeType=${object.mimeType}&kuraStorageUserId=${localStorage.getItem('userId')}&openApp=voiceGeneratorNoen`}
            target="_blank" rel="noreferrer noopener"
            style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
          >
            {marks.editMark}{' '}
            {t('fileAction.26', 'open in')}
            {' '}Voice Generator {marks.externalLinkMark}
          </a>
        </button>
        <button className={classes.objectActionListItem}
          style={{ paddingLeft: "1rem" }}
          disabled={isLoading}
          name="open-in-answer-finder"
          onClick={(event) => {
            if (!isLoading) {
              setClickedActionInfoHandler(event, object.Key);
              setClickedObjectKeyHandler('');
              // tokenForToolsHandler(
              //   BACKEND_URL,
              //   localStorage.getItem('token'),
              //   fileBucket,
              //   object.Key,
              // );
            }
          }}
        >
          <a 
            // href={`${dreamInpaniterLink}/?key=${object.Key}&mimeType=${object.mimeType}&tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
            href={`${textAudioToolsLink}/answer-finder/?key=${object.Key}&mimeType=${object.mimeType}&kuraStorageUserId=${localStorage.getItem('userId')}&openApp=answerFinder`}
            target="_blank" rel="noreferrer noopener"
            style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
          >
            {marks.editMark}{' '}
            {t('fileAction.26', 'open in')}
            {' '}Answer Finder {marks.externalLinkMark}
          </a>
        </button>
      </>
    );
  }


  return (
    <Fragment>
      {textAudioToolsLinkBody}
    </Fragment>
  );
}

export default TextAudioToolsLink;
